import { Grid, makeStyles, MenuItem, Popover } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connectWithStore } from "../../../services/redux";
import {
  BLUEPRINT_ID,
  CIRCLE_TOOL,
  LAMP_TOOL,
  LINE_TOOL,
  MEASURE_TOOL_HORIZONTAL,
  MEASURE_TOOL_VERTICAL,
  MOVE_TOOL,
  PEN_TOOL,
  POLYGON_TOOL,
  POLYGON_OPEN,
  RECT_TOOL,
  SCISSORS_TOOL,
} from "./constants";
import QueueIcon from "@material-ui/icons/Queue";
import { BlueprintEditor } from "../../../redux/actions";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import Konva from "konva";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { isMobileDevice } from "../../../services/mobile";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import TableChart from "@material-ui/icons/TableChart";
import { showLoading } from "react-redux-loading-bar";
import {
  removeElements,
  areMeasuresSet,
  canSet,
  removeTransformers,
  setScaledPoints,
  removeShape,
  getUnitSize,
  getUnit,
  getElementSize,
  convertPixelsToUnit,
  getAreaFromCoords,
  getLinearLength,
} from "./utils";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import i18n from "../../../i18n";
import { batch } from "react-redux";
import Confirm from "../../confirm/confirm";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import FilterBAndWIcon from "@material-ui/icons/FilterBAndW";
import ReactTooltip from "react-tooltip";
import { CONSTRUCTOR_NAME } from "./constants";
import { rotateFile } from "../../../services/api/files";
import PolygonSidesInput from "./polygon_sides_input";
import { exportMarkingsXslx } from "../../../services/api/blueprints";

const useStyles = makeStyles((theme) => ({
  container: {
    //backgroundColor: theme.palette.primary.light,
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    flexDirection: isMobileDevice() ? "row" : "column",
  },
  dropdownSelect: {
    padding: 0,
  },
  button: {
    margin: 5,
  },
  toolBarItem: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  toolRow: {
    display: "flex",
    alignItems: "center",
  },
  toolIcon: {
    fontSize: "1.6rem",
    marginRight: "0.8rem",
  },
}));

const ICON_MAP = {
  [SCISSORS_TOOL]: "content-cut",
  [PEN_TOOL]: "lead-pencil",
  [RECT_TOOL]: "square",
  [CIRCLE_TOOL]: "checkbox-blank-circle",
  [LINE_TOOL]: "vector-line",
  [MOVE_TOOL]: "cursor-move",
  [LAMP_TOOL]: "white-balance-incandescent",
  [POLYGON_TOOL]: "pentagon",
  [POLYGON_OPEN]: "sigma",
};

const ICON_MAP_OUTLINED = {
  [RECT_TOOL]: "square-outline",
  [CIRCLE_TOOL]: "checkbox-blank-circle-outline",
  [POLYGON_TOOL]: "pentagon-outline",
};

const MEASURE_TOOL_HORIZONTAL_INFO = {
  type: MEASURE_TOOL_HORIZONTAL,
  color: "#000",
  name: i18n.t("default:_MEASURE_TOOL_HORIZONTAL"),
};

const MEASURE_TOOL_VERTICAL_INFO = {
  type: MEASURE_TOOL_VERTICAL,
  color: "#000",
  name: i18n.t("default:_MEASURE_TOOL_VERTICAL"),
};

const getIcon = (type, outlined) => {
  return (outlined ? ICON_MAP_OUTLINED[type] : ICON_MAP[type]) || "help";
};

const ToolRenderer = React.forwardRef(function ToolRenderer({
  id,
  tool,
  onClick,
  role,
}) {
  const classes = useStyles();

  if (
    tool.type !== MEASURE_TOOL_HORIZONTAL ||
    tool.type !== MEASURE_TOOL_VERTICAL
  ) {
    return (
      <MenuItem
        key={id}
        onClick={(e) => (onClick ? onClick(e) : null)}
        className={classes.toolBarItem}
      >
        <span className={classes.toolRow}>
          <i
            className={`mdi mdi-${getIcon(tool.type, tool.outlined)} ${classes.toolIcon
              }`}
            style={{ color: tool.color || "#000" }}
          />
          {tool.name}
        </span>
      </MenuItem>
    );
  }
});

function BlueprintEditorToolbar({
  blueprintEditor,
  dispatch,
  user,
  settings,
  updateFileProp,
  job,
}) {
  const {
    layer,
    app,
    toolbar,
    mode,
    history,
    lastHistoryIndex,
    layerPanelOpen,
    imageRotation,
    imageGrayscale,
    fileId,
    showEditBpModal,
    measurements,
    pdfInfo,
    elements,
    isMeasuring,
    showHeightInput,
    showWidthInput,
    tool,
    selectedElement,
  } = blueprintEditor;
  const [anchorEl, setAnchorEl] = useState(null);
  const [measureAnchorEl, setMeasureAnchorEl] = useState(null);
  const [resetConfirm, setResetConfirm] = useState(false);
  const [polygonInputOpen, setPolugonInputOpen] = useState(false);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const { profile } = user;
  const classes = useStyles();

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMeasureClick = (event) => {
    setMeasureAnchorEl(event.currentTarget);
  };

  const handleSelectMeasureClose = () => {
    setMeasureAnchorEl(null);
  };

  const measureOpen = Boolean(measureAnchorEl);
  const measureId = measureOpen ? "simple-popover" : undefined;

  const isUndoDisabled = useCallback(() => {
    return history.length === 0 || lastHistoryIndex === 0;
  }, [lastHistoryIndex, history.length]);

  const isRedoDisabled = useCallback(() => {
    return history.length === 0 || lastHistoryIndex === history.length - 1;
  }, [history.length, lastHistoryIndex]);

  const handleToolChange = useCallback(
    (tool) => {
      dispatch(BlueprintEditor.changeTool(tool));
      removeTransformers();
      dispatch(BlueprintEditor.setSelectedElement(null));
      handleSelectClose();
      if (tool.type === POLYGON_OPEN) {
        setPolugonInputOpen(true);
      }
    },
    [dispatch]
  );

  const handleMeasureChange = (tool) => {
    dispatch(BlueprintEditor.changeTool(tool));
    handleSelectMeasureClose();
    blueprintEditor.app.draggable(false);
  };

  const handleReplayMeasureIntro = () => {
    handleSelectMeasureClose();
    dispatch(BlueprintEditor.setMeasureIntroDisplayed(false));
  };

  const updateShapesAttributes = (historyElements) => {
    const reduxElements = [];
    const filteredElements = historyElements.filter(
      (el) => el.name !== "Image" && el.name !== "Transformer"
    );
    filteredElements.forEach((currentEl) => {
      const { attrs, elementId, created_at } = currentEl;
      const currentElInfoArr = blueprintEditor.elements.filter(
        (el) => el.id === attrs.id
      );
      const currentElInfo =
        currentElInfoArr && currentElInfoArr.length > 0
          ? currentElInfoArr[0]
          : null;
      const currentElJob = currentElInfo ? currentElInfo.job : null;
      const currentElQuestion = currentElInfo ? currentElInfo.question : null;
      if (!attrs.scaleX) {
        attrs.scaleX = 1;
        attrs.scaleY = 1;
      }
      const shape = blueprintEditor.app.find("#" + attrs.id)[0];
      if (shape) {
        shape.setAttrs(attrs);
        reduxElements.push({
          id: attrs.id,
          elementId,
          user,
          visible: attrs.visible,
          created_at,
          job: currentElJob,
          question: currentElQuestion,
        });
      }
    });
    blueprintEditor.app.batchDraw();
    dispatch(BlueprintEditor.setElements(reduxElements));
  };

  const hideTooltip = () => {
    if (isMobileDevice()) {
      setTimeout(() => {
        ReactTooltip.hide();
      }, 2000);
    }
  };

  const onUndo = () => {
    hideTooltip();
    if (app) {
      const prevIndex = lastHistoryIndex - 1;
      if (prevIndex >= 0) {
        const historyElements = history[prevIndex];
        const currentElements = history[lastHistoryIndex];

        if (historyElements.length < currentElements.length) {
          const difference = historyElements.length;
          const filteredArray = currentElements.slice(difference);
          filteredArray.forEach((currentEl) => {
            const { attrs } = currentEl;
            if (attrs.id) {
              if (
                blueprintEditor.selectedElement &&
                attrs.id === blueprintEditor.selectedElement.attrs.id
              ) {
                dispatch(BlueprintEditor.setSelectedElement(null));
              }
              dispatch(BlueprintEditor.removeElement(attrs.id));

              removeTransformers();

              const shape = blueprintEditor.app.find("#" + attrs.id)[0];
              if (shape) {
                removeShape(shape);
              }
            }
          });
        } else {
          updateShapesAttributes(historyElements);
        }
        dispatch(BlueprintEditor.undo());
      }
    }
  };

  const resetMeasurements = useCallback(() => {
    removeElements(CONSTRUCTOR_NAME.ARROW);
    setResetConfirm(false);
    batch(() => {
      dispatch(BlueprintEditor.resetMeasurements());
      dispatch(
        BlueprintEditor.setFloatingInput({
          display: "none",
        })
      );
    });
    updateFileProp("measurements", null, fileId);
  }, [dispatch, updateFileProp, fileId]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        if (
          tool &&
          (tool.type === POLYGON_TOOL || tool.type === POLYGON_OPEN)
        ) {
          const myCustomEvent = new CustomEvent("cancelPolygonDrawing");
          // Dispatch the custom event on the document
          return document.dispatchEvent(myCustomEvent);
        }
        if (isMeasuring || showHeightInput || showWidthInput) {
          batch(() => {
            dispatch(BlueprintEditor.setIsMeasuring(false));
            dispatch(BlueprintEditor.setShowWidthInput(false));
            dispatch(BlueprintEditor.setShowHeightInput(false));
            dispatch(BlueprintEditor.setSelectedElement(null));
          });
          resetMeasurements();

          if (layer) {
            layer.batchDraw();
          }
        }
      }
      // eslint-disable-next-line
    },
    [
      isMeasuring,
      showHeightInput,
      showWidthInput,
      dispatch,
      tool,
      layer,
      resetMeasurements,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        event.preventDefault();
        setCtrlPressed(true);
      }
      if (ctrlPressed) {
        switch (event.key) {
          case "ArrowUp":
            selectedElement.y(selectedElement.y() - 1);
            // setPosition((prevPosition) => ({ ...prevPosition, top: prevPosition.top - 10 }));
            dispatch(
              BlueprintEditor.updateElement({
                id: selectedElement.getAttr("id"),
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
            break;
          case "ArrowDown":
            //setPosition((prevPosition) => ({ ...prevPosition, top: prevPosition.top + 10 }));

            selectedElement.y(selectedElement.y() + 1);
            dispatch(
              BlueprintEditor.updateElement({
                id: selectedElement.getAttr("id"),
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
            break;
          case "ArrowLeft":
            // setPosition((prevPosition) => ({ ...prevPosition, left: prevPosition.left - 10 }));
            selectedElement.x(selectedElement.x() - 1);
            dispatch(
              BlueprintEditor.updateElement({
                id: selectedElement.getAttr("id"),
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
            break;
          case "ArrowRight":
            //  setPosition((prevPosition) => ({ ...prevPosition, left: prevPosition.left + 10 }));
            selectedElement.x(selectedElement.x() + 1);
            dispatch(
              BlueprintEditor.updateElement({
                id: selectedElement.getAttr("id"),
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
            break;
          default:
            break;
        }
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Control") {
        setCtrlPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    ctrlPressed,
    selectedElement,
    blueprintEditor,
    job,
    tool,
    profile,
    dispatch,
  ]);

  const onRedo = () => {
    hideTooltip();
    if (app) {
      const nextIndex = lastHistoryIndex + 1;
      if (nextIndex <= history.length - 1) {
        const historyElements = history[nextIndex];
        const currentElements = history[lastHistoryIndex];

        if (historyElements.length > currentElements.length) {
          //re-draw element
          const difference = currentElements.length;
          const filteredArray = historyElements.slice(difference);

          filteredArray.forEach((currentEl) => {
            const { attrs, name, elementId } = currentEl;
            const elName = name === "Doodle" ? "Line" : name;
            if (attrs.id) {
              const node = Konva.Node.create({
                attrs,
                className: elName,
              });
              node.on("dragstart", () => {
                if (blueprintEditor.mode !== "EDIT") {
                  node.stopDrag();
                }
              });
              node.on("transformend", () => {
                if (
                  node.attrs &&
                  node.attrs.name &&
                  node.attrs.name === "Polygon"
                ) {
                  setScaledPoints(node);
                }
                dispatch(
                  BlueprintEditor.updateElement({
                    id: node.getAttr("id"),
                    elementId: elementId,
                    user: user.profile,
                    visible: true,
                  })
                );
              });
              node.on("dragend", () => {
                dispatch(
                  BlueprintEditor.updateElement({
                    id: node.getAttr("id"),
                    elementId: elementId,
                    user: user.profile,
                    visible: true,
                  })
                );
              });
              dispatch(
                BlueprintEditor.addElement({
                  id: attrs.id,
                  elementId: elementId,
                  user: user.profile,
                  //created_at:
                })
              );
              blueprintEditor.layer.add(node);
              blueprintEditor.layer.batchDraw();
            }
          });
        } else {
          updateShapesAttributes(historyElements);
        }
        dispatch(BlueprintEditor.redo());
      }
    }
  };

  const closeConfirm = () => {
    setResetConfirm(false);
  };

  const onRotateClick = async () => {
    dispatch(showLoading());
    const { imgSource, size } = pdfInfo;

    const newRotation = imageRotation + 90;
    const params = {
      id: imgSource,
      rotation: 90,
    };

    const response = await rotateFile(params);

    if (response.success) {
      const previousLayer = { ...blueprintEditor.layer };
      dispatch(BlueprintEditor.setPreviuosLayer(previousLayer));
      //remove previous image
      if (
        blueprintEditor.layer.children &&
        blueprintEditor.layer.children.length > 0
      ) {
        const childrenArr = [...blueprintEditor.layer.children];
        const imageArr =
          childrenArr.length > 0 &&
          childrenArr.filter((el) => el.attrs && el.attrs.id === BLUEPRINT_ID);
        const image = imageArr && imageArr[0];
        const id = image && image.attrs.id;
        if (id) {
          const shape = blueprintEditor.app.find("#" + id)[0];
          if (shape) {
            shape.destroy();
            blueprintEditor.app.batchDraw();
          }
        }
      }

      //revert height and width in pdf sizes to update canvas dimensions
      const previousHeight = size && size.height;
      const previousWidth = size && size.width;
      const previousFitSize = size && size.fitSize;
      const newSize = {
        height: previousWidth,
        width: previousHeight,
        fitSize: {
          height: previousFitSize && previousFitSize.width,
          width: previousFitSize && previousFitSize.height,
        },
      };
      let updatedPdfInfo = { ...pdfInfo };
      updatedPdfInfo.size = newSize;
      dispatch(BlueprintEditor.setPdfInfo(updatedPdfInfo));

      //update project field
      dispatch(BlueprintEditor.setImageRotation(newRotation));
      updateFileProp("rotation", newRotation, fileId);
    }
  };

  const onGrayscaleClick = () => {
    const previousLayer = { ...blueprintEditor.layer };
    dispatch(BlueprintEditor.setPreviuosLayer(previousLayer));
    dispatch(BlueprintEditor.setImageGrayscale(!imageGrayscale));
    updateFileProp("grayscale", !imageGrayscale, fileId);
  };

  const renderMeasureOptions = () => {
    return (
      <>
        {(!measurements ||
          (measurements && !measurements.arrows) ||
          (measurements &&
            measurements.arrows &&
            !measurements.arrows.height)) && (
            <MenuItem
              className="vertical_measure"
              onClick={() => handleMeasureChange(MEASURE_TOOL_VERTICAL_INFO)}
            >
              <span>{i18n.t("default:_MEASURE_TOOL_VERTICAL")}</span>
            </MenuItem>
          )}
        {(!measurements ||
          (measurements && !measurements.arrows) ||
          (measurements &&
            measurements.arrows &&
            !measurements.arrows.width)) && (
            <MenuItem
              className="horizontal_measure"
              onClick={() => handleMeasureChange(MEASURE_TOOL_HORIZONTAL_INFO)}
            >
              <span>{i18n.t("default:_MEASURE_TOOL_HORIZONTAL")}</span>
            </MenuItem>
          )}
        <MenuItem onClick={() => setResetConfirm(true)}>
          <span>{i18n.t("default:_RESET_MEASURES")}</span>
        </MenuItem>
        <MenuItem onClick={() => handleReplayMeasureIntro()}>
          <span>{i18n.t("default:_REPLAY_MEASURE_INTRO")}</span>
        </MenuItem>
      </>
    );
  };

  const isBpClean = () => {
    if (elements.length > 0) {
      return false;
    }
    if (measurements && measurements.arrows) {
      return false;
    }
    return true;
  };

  const onPolygonSidesSet = () => {
    setPolugonInputOpen(false);
  };

  const getTotaLength = (shape, width, height) => {
    if (!shape) return null;
    if (shape.attrs.name === CONSTRUCTOR_NAME.LINE) {
      return Number(width);
    }
    if (shape.attrs.name === CONSTRUCTOR_NAME.RECT) {
      const perimeter = (width + height) * 2;
      return Number(perimeter.toFixed(2));
    }
    if (shape.attrs.name === CONSTRUCTOR_NAME.CIRCLE) {
      const radius = shape.radius();
      const convertedRadius = Number(convertPixelsToUnit(radius, "width"));
      const perimeter = 2 * convertedRadius * Math.PI;
      return Number(perimeter.toFixed(2));
    }
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.POLYGON ||
      shape.attrs.name === CONSTRUCTOR_NAME.DOODLE
    ) {
      const points = shape.attrs.points;
      const polygonLength = getLinearLength(points);
      return polygonLength;
    }
    return null;
  };

  const getTotalArea = (shape, width, height) => {
    if (!shape) return null;
    //get area of filled elements
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.RECT &&
      shape.attrs.fill !== "rgba(0, 0, 0, 0)"
    ) {
      const sqArea = width * height;
      return Number(sqArea.toFixed(2));
    }
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.CIRCLE &&
      shape.attrs.fill !== "rgba(0, 0, 0, 0)"
    ) {
      const radius = shape.radius();
      const convertedRadius = Number(convertPixelsToUnit(radius, "width"));
      const circleArea = Math.PI * convertedRadius * convertedRadius;
      return Number(circleArea.toFixed(2));
    }
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.POLYGON &&
      shape.attrs.fill &&
      shape.attrs.fill !== "rgba(0, 0, 0, 0)"
    ) {
      const points = shape.attrs.points;
      const polygonArea = getAreaFromCoords(points);
      return Number(polygonArea);
    }
    return null;
  };

  const hasElements = () => {
    const ids = blueprintEditor.elements
      .filter((e) => e.visible)
      .map((e) => e.id);
    return ids.length > 0;
  }

  const excelExport = async () => {
    const elements = [];
    const units = getUnit();
    const ids = blueprintEditor.elements
      .filter((e) => e.visible)
      .filter((e) => job ? e.job.ID === job.ID : true)
      .map((e) => e.id);
    blueprintEditor.jobs.forEach((job) => {
      job.elements
        .filter((elementFilter) => ids.includes(elementFilter._id))
        .forEach((el) => {
          const tools = settings.blueprintSettings.elements.filter(
            (t) => t.id === el.elementId
          );
          if (tools.length > 0) {
            const { width, height } = getElementSize(el.canvasData);
            const elementTarget = blueprintEditor.elements.filter(
              (e) => e.id === el._id
            );
            const targetId =
              elementTarget && elementTarget[0] && elementTarget[0].id;
            const shape = blueprintEditor.app.find("#" + targetId)[0];
            const sizeWidth = getUnitSize(shape, width);

            const convertedWidth =
              shape && shape.attrs && shape.attrs.name === "Polygon"
                ? ""
                : shape && shape.attrs && shape.attrs.name === "Line"
                  ? sizeWidth.replace("m", "")
                  : Number(convertPixelsToUnit(width, "width"));
            const convertedHeight =
              shape && shape.attrs && shape.attrs.name === "Polygon"
                ? ""
                : Number(convertPixelsToUnit(height, "height"));

            //set length (perimeter) and area of the element
            const totalLength = getTotaLength(
              shape,
              convertedWidth,
              convertedHeight
            );
            const totalArea = getTotalArea(
              shape,
              convertedWidth,
              convertedHeight
            );
            elements.push({
              blueprint: job,
              element: elementTarget[0],
              color: tools[0].color,
              name: tools[0].name,
              type: tools[0].type,
              width:
                shape && shape.attrs && shape.attrs.name === "Polygon"
                  ? ""
                  : sizeWidth,
              convertedHeight,
              convertedWidth,
              totalLength,
              totalArea,
            });
          } else {
            elements.push({
              blueprint: job,
              element: el,
              color: { hex: "#000" },
              name: i18n.t("default:_REMOVED_ELEMENT"),
              type: -1,
              width: 0 + units,
            });
          }
        });
    });
    const response = await exportMarkingsXslx(elements);

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(response);

    // Create a temporary <a> element and set the download attribute
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", elements[0].blueprint.job.project.name); // Set the file name for download

    // Append to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link element
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Grid className={classes.container}>
      <PolygonSidesInput
        open={polygonInputOpen}
        handleClose={() => onPolygonSidesSet()}
      />
      <ReactTooltip id="toolbar" effect="solid" />
      <Confirm
        title={i18n.t("default:_ARE_YOU_SURE")}
        message={i18n.t("default:_CONFIRM_RESET_MEASURES")}
        show={resetConfirm}
        onConfirm={() => resetMeasurements()}
        onClose={() => closeConfirm()}
      />
      {mode === "EDIT" && !showEditBpModal && (
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          className={classes.button}
          onClick={handleAddClick}
          data-place={isMobileDevice() ? "top" : "right"}
          data-tip={i18n.t("default:_ADD")}
          data-for="toolbar"
        >
          <AddIcon />
        </Fab>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleSelectClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {toolbar &&
          toolbar.length > 0 &&
          toolbar.map((t, index) => {
            if (
              t.type === MOVE_TOOL ||
              t.type === MEASURE_TOOL_HORIZONTAL ||
              t.type === MEASURE_TOOL_VERTICAL
            ) {
              return null;
            }
            return (
              <ToolRenderer
                key={index}
                id={index}
                onClick={(e) => handleToolChange(t)}
                tool={t}
                role={user.profile.role}
              />
            );
          })}
      </Popover>
      {mode === "SET" && canSet(user, settings) && (
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          className={`add_icon ${classes.button}`}
          id="measure_fab"
          data-place={isMobileDevice() ? "top" : "right"}
          data-tip={
            areMeasuresSet(blueprintEditor.measurements)
              ? i18n.t("default:_RESET_MEASURES")
              : i18n.t("default:_SET_MEASURES")
          }
          data-for="toolbar"
          onClick={(e) =>
            areMeasuresSet(blueprintEditor.measurements)
              ? setResetConfirm(true)
              : handleMeasureClick(e)
          }
        >
          {blueprintEditor.measurements &&
            Object.keys(blueprintEditor.measurements).length > 0 ? (
            <FormatLineSpacingIcon />
          ) : (
            <i style={{ fontSize: "1.5rem" }} className="mdi mdi-move-resize" />
          )}
        </Fab>
      )}
      <Popover
        id={measureId}
        open={measureOpen}
        anchorEl={measureAnchorEl}
        onClose={handleSelectMeasureClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {renderMeasureOptions()}
      </Popover>
      {mode === "SET" && canSet(user, settings) && (
        <span
          data-place={isMobileDevice() ? "top" : "right"}
          data-tip={
            isBpClean()
              ? i18n.t("default:_ROTATE")
              : i18n.t("default:_ROTATION_DISABLED")
          }
          data-tip-disable={false}
          data-for="toolbar"
        >
          <Fab
            size="medium"
            color="primary"
            aria-label="rotate"
            className={classes.button}
            onClick={() => onRotateClick()}
            disabled={!isBpClean()}
          >
            <RotateRightIcon />
          </Fab>
        </span>
      )}
      {mode === "SET" && canSet(user, settings) && (
        <Fab
          size="medium"
          color="primary"
          aria-label="greyscale"
          className={classes.button}
          onClick={() => onGrayscaleClick()}
          data-place={isMobileDevice() ? "top" : "right"}
          data-tip={i18n.t("default:_GRAYSCALE")}
          data-for="toolbar"
        >
          <FilterBAndWIcon />
        </Fab>
      )}
      <Fab
        size="medium"
        color="primary"
        aria-label="queue"
        className={classes.button}
        onClick={() => {
          hideTooltip();
          layerPanelOpen
            ? dispatch(BlueprintEditor.closeLayerPanel())
            : dispatch(BlueprintEditor.openLayerPanel());
        }}
        data-place={isMobileDevice() ? "top" : "right"}
        data-tip={i18n.t("default:_ELEMENT_INFO_PANEL")}
        data-for="toolbar"
      >
        <QueueIcon />
      </Fab>
      <Fab
        size="medium"
        color="primary"
        aria-label="pdf"
        className={classes.button}
        //onClick={() => exportPdf()}
        onClick={() => dispatch(BlueprintEditor.setPdfExportModalVisible(true))}
        data-place={isMobileDevice() ? "top" : "right"}
        data-tip={i18n.t("default:_EXPORT")}
        data-for="toolbar"
      >
        <PictureAsPdfIcon />
      </Fab>
      <Fab
        size="medium"
        color="primary"
        aria-label="excel"
        className={classes.button}
        //onClick={() => exportPdf()}
        onClick={() => excelExport()}
        data-place={isMobileDevice() ? "top" : "right"}
        data-tip={i18n.t("default:_EXPORT")}
        data-for="toolbar"
        disabled={!hasElements()}
      >
        <TableChart />
      </Fab>
      {mode !== "SET" && (
        <Fab
          size="medium"
          color="primary"
          aria-label="undo"
          className={classes.button}
          onClick={() => onUndo()}
          disabled={isUndoDisabled()}
          data-place={isMobileDevice() ? "top" : "right"}
          data-tip={i18n.t("default:_UNDO")}
          data-for="toolbar"
        >
          <UndoIcon />
        </Fab>
      )}
      {mode !== "SET" && (
        <Fab
          size="medium"
          color="primary"
          aria-label="redo"
          className={classes.button}
          onClick={() => onRedo()}
          disabled={isRedoDisabled()}
          data-place={isMobileDevice() ? "top" : "right"}
          data-tip={i18n.t("default:_REDO")}
          data-for="toolbar"
        >
          <RedoIcon />
        </Fab>
      )}
    </Grid>
  );
}

export default connectWithStore(BlueprintEditorToolbar, [
  "blueprintEditor",
  "user",
  "settings",
]);
