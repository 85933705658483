import Http from "../http";

export const getWidgetsList = async () => {
  const path = `/v2/widgets/dashboard`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getWidget = async (params) => {
  const path = `/v2/widgets/dashboard/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const updateWidget = async (params) => {
  const path = `/v2/widgets/dashboard/${params.id}`;
  try {
    const response = await Http.sharedInstance
      .withAuth()
      .put(path, params.data);
    return response.data;
  } catch (e) {
    return [];
  }
};
